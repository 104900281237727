// Editor theme
import "ace-builds/src-noconflict/theme-monokai";

// Languages
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";

// Split Editor Props: https://github.com/securingsincity/react-ace/blob/master/docs/Split.md

// name	'ace-editor'	String	Unique Id to be used for the editor
// mode	''	String	Language for parsing and code highlighting
// splits	2	Number	Number of views to have
// orientation	'beside'	String	The orientation of the splits either beside or below
// theme	''	String	theme to use
// value	''	Array of Strings	value you want to populate in each code editor
// defaultValue	''	Array of Strings	Default value for each editor
// height	'500px'	String	CSS value for height
// width	'500px'	String	CSS value for width
// className		String	custom className
// fontSize	12	Number	pixel value for font-size
// showGutter	true	Boolean	show gutter
// showPrintMargin	true	Boolean	show print margin
// highlightActiveLine	true	Boolean	highlight active line
// focus	false	Boolean	whether to focus
// cursorStart	1	Number	the location of the cursor
// wrapEnabled	false	Boolean	Wrapping lines
// readOnly	false	Boolean	make the editor read only
// minLines		Number	Minimum number of lines to be displayed
// maxLines		Number	Maximum number of lines to be displayed
// enableBasicAutocompletion	false	Boolean	Enable basic autocompletion
// enableLiveAutocompletion	false	Boolean	Enable live autocompletion
// tabSize	4	Number	tabSize
// debounceChangePeriod	null	Number	A debounce delay period for the onChange event
// onLoad		Function	called on editor load. The first argument is the instance of the editor
// onBeforeLoad		Function	called before editor load. the first argument is an instance of ace
// onChange		Function	occurs on document change it has 2 arguments the value of each editor and the event.
// onCopy		Function	triggered by editor copy event, and passes text as argument
// onPaste		Function	Triggered by editor paste event, and passes text as argument
// onSelectionChange		Function	triggered by editor selectionChange event, and passes a Selection as it's first argument and the event as the second
// onCursorChange		Function	triggered by editor changeCursor event, and passes a Selection as it's first argument and the event as the second
// onFocus		Function	triggered by editor focus event
// onBlur		Function	triggered by editor blur event
// onInput		Function	triggered by editor input event
// onScroll		Function	triggered by editor scroll event
// editorProps		Object	properties to apply directly to the Ace editor instance
// setOptions		Object	options to apply directly to the Ace editor instance
// keyboardHandler		String	corresponding to the keybinding mode to set (such as vim or emacs)
// commands		Array	new commands to add to the editor
// annotations		Array of Arrays	annotations to show in the editor i.e. [{ row: 0, column: 2, type: 'error', text: 'Some error.'}], displayed in the gutter
// markers		Array of Arrays	markers to show in the editor, i.e. [{ startRow: 0, startCol: 2, endRow: 1, endCol: 20, className: 'error-marker', type: 'background' }]
// style		Object	camelCased properties

/**
 *
 *
 * @export
 * @param {*} {
 *   name = 'na',
 *   mode = 'javascript',
 *   splits = 2,
 *   theme = 'monokai',
 *   value = '() => {}',
 *   defaultValue = '() => {}',
 *   height = '400px',
 *   width = '400px',
 *   className = '',
 *   orientation = 'beside',
 *   fontSize = '14px',
 *   showPrintMargin = true,
 *   showGutter = true,
 *   highlightActiveLine = true,
 *   placeholder = 'Placeholder Text',
 *   cursorStart = 1,
 *   wrapEnabled = false,
 *   readOnly = false,
 *   enableBasicAutocompletion = false,
 *   enableLiveAutocompletion = false,
 *   tabSize = 4,
 *   focus = false,
 *   onLoad = () => null,
 *   onBeforeLoad = () => null,
 *   onChange = () => null,
 *   onCopy = () => null,
 *   onPaste = () => null,
 *   onSelectionChange = () => null,
 *   onCursorChange = () => null,
 *   onFocus = () => null,
 *   onBlur = () => null,
 *   onInput = () => null,
 *   onScroll = () => null,
 *   editorProps = {},
 *   setOptions = {}
 * }
 * @returns {object} - object containing configuration options for a "Split" Ace editor
 */
export function getEditorBaseConfig({
  name = "na",
  mode = "javascript",
  splits = 2,
  theme = "monokai",
  value = ["() => {}", "() => {}"],
  defaultValue = ["() => {}", "() => {}"],
  height = "80vh",
  width = "100vw",
  className = "",
  orientation = "beside",
  fontSize = "14px",
  showPrintMargin = true,
  showGutter = true,
  highlightActiveLine = true,
  placeholder = "Placeholder Text",
  cursorStart = 1,
  wrapEnabled = false,
  readOnly = false,
  enableBasicAutocompletion = false,
  enableLiveAutocompletion = false,
  tabSize = 4,
  focus = true,
  onLoad = () => null,
  onBeforeLoad = () => null,
  onChange = () => null,
  onCopy = () => null,
  onPaste = () => null,
  onSelectionChange = () => null,
  onCursorChange = () => null,
  onFocus = () => null,
  onBlur = () => null,
  onInput = () => null,
  onScroll = () => null,
  editorProps = {},
  setOptions = {}
}) {
  return {
    name,
    mode,
    splits,
    theme,
    value,
    defaultValue,
    height,
    width,
    className,
    orientation,
    fontSize,
    showPrintMargin,
    showGutter,
    highlightActiveLine,
    placeholder,
    cursorStart,
    wrapEnabled,
    readOnly,
    enableBasicAutocompletion,
    enableLiveAutocompletion,
    tabSize,
    focus,
    onLoad,
    onBeforeLoad,
    onChange,
    onCopy,
    onPaste,
    onSelectionChange,
    onCursorChange,
    onFocus,
    onBlur,
    onInput,
    onScroll,
    editorProps,
    setOptions
  };
}

// Default IDE values

const javaFunctionStr = `public class StringExample
{	public static void main(String[] args)
	{	String s1 = "Computer Science";
		int x = 307;
		String s2 = s1 + " " + x;
		String s3 = s2.substring(10,17);
		String s4 = "is fun";
		String s5 = s2 + s4;

		System.out.println("s1: " + s1);
		System.out.println("s2: " + s2);
		System.out.println("s3: " + s3);
		System.out.println("s4: " + s4);
		System.out.println("s5: " + s5);

		//showing effect of precedence

		x = 3;
		int y = 5;
		String s6 = x + y + "total";
		String s7 = "total " + x + y;
		String s8 = " " + x + y + "total";
		System.out.println("s6: " + s6);
		System.out.println("s7: " + s7);
		System.out.println("s8: " + s8);
	}
}`;

const javaScriptFunctionStr1 = `
const array1 = [1, 2, 3, 4];
const reducer = (accumulator, currentValue) => accumulator + currentValue;

// 1 + 2 + 3 + 4
console.log(array1.reduce(reducer));
// expected output: 10

// 5 + 1 + 2 + 3 + 4
console.log(array1.reduce(reducer, 5));
// expected output: 15
`;

const javaScriptFunctionStr2 = `
// 1 + 2 + 3 + 4
console.log(array1.reduce(reducer));
// expected output: 10

// 5 + 1 + 2 + 3 + 4
console.log(array1.reduce(reducer, 5));
// expected output: 15
`;

const pythonFunctionStr = `
import datetime
now = datetime.datetime.now()
print "-" * 25
print now
print now.year
print now.month
print now.day
print now.hour
print now.minute
print now.second

print "-" * 25
print "1 week ago was it: ", now - datetime.timedelta(weeks=1)
print "100 days ago was: ", now - datetime.timedelta(days=100)
print "1 week from now is it: ",  now + datetime.timedelta(weeks=1)
print "In 1000 days from now is it: ", now + datetime.timedelta(days=1000)

print "-" * 25
birthday = datetime.datetime(2012,11,04)

print "Birthday in ... ", birthday - now
print "-" * 25
`;

export const defaultEditorStrings = {
  java: javaFunctionStr,
  js: [javaScriptFunctionStr1, javaScriptFunctionStr2],
  python: pythonFunctionStr
};
