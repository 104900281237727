import React from 'react';
import './style.css';
//import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
const dashboardRoutes = [];

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: '5em',
        marginBottom: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

function Impressum() {
    const classes = useStyles();

    //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <div className={classes.root}>
            <main className={classes.content}>
            <Header
                color="transparent"
                backgroundColor="rgb(244, 244, 245)"
                routes={dashboardRoutes}
                brand="Mein Name ist Jeff."
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
            />
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {/* Recent Orders */}
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <h3>Willkommen bei TensorParc</h3>
                                <p>Wir sind ein Team von Freunden, die sich vorgenommen haben, das Lernen und Forschen zu demokratisieren. <br/>
                                <br/>
                                Die Mehrheit akzeptiert Angebot und Nachfrage, um unsere wirtschaftlichen Angelegenheiten zu regeln. Aber wenn es um Bildung geht, insbesondere um das, was man lernt, sind die meisten Menschen mit einem festen Lehrplan zufrieden. Das ist für die ersten 8 Schuljahre in Ordnung. Darüber hinaus muss die Bildung frei genug sein, um den Menschen zu ermöglichen, das zu studieren, was sie interessiert. Heute lernen die meisten nur, um Prüfungen zu bestehen. Stelle Dir eine Welt vor, in der Menschen nur um des Lernens willen lernen. Dies geschieht, so glauben wir, wenn die Menschen das lernen können, was sie wollen.
                                <br/> <br/>
                                Ein wenig nerdig: Wir sehen uns als <a href="/" title="if you want to learn more about recursive functions, please feel free to use our tool Isa">recursive Funktion</a> die dafür sorgt, dass jeder eine Eins in allem bekommt. Deswegen haben wir haben TensorParc gegründet.
                                <br/> <br/>
                                Wir haben bisher zwei Produkte mit unserer Kern-Software ausgeliefert: Sie heißen Isa und Jeff. <br/>
                                <a href="https://isa.tensorparc.com">Isa</a> wird Dir beim Lernen helfen. Isa ist nach einer Funktion benannt, die andere Funktionen aufruft, um Dinge zu überprüfen. <a href="https://jeff.tensorparc.com">Jeff</a> wird Dir helfen, die richtigen Worte für Deinen Text zu finden. Jeff wird nach Jeff genannt.
                                Isa is named after a function that calls other functions to check stuff. Jeff is called after Jeff. <br/><br/>
                                Wir bauen Produkte mit möglichst geringen Nebenwirkungen. Aus Deiner Sicht sind Nebenwirkungen Funktionen, die Dir als Benutzer keinen Wert zurückgeben. Technisch gesehen konzentrieren wir uns auf funktionale Programmierung und sauberes Design. Deshalb mag unsere App einfach aussehen, aber die Schlüsselfunktionen, die wir anbieten, sind leistungsstark.
                                <br/><br/>
                                Wenn Du Ideen hast, die wir ausprobieren sollten, lasse es uns bitte wissen.<br/><br/>
                                Wir sind Tony Meyer, Farid Bidardel, Tobias Lang, Karan Dehghani.</p>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                    <Footer/>
            </main>
        </div>
    );
}

export default Impressum;
