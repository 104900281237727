import React, { PureComponent, Fragment } from "react";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { withStyles } from "@material-ui/core/styles";
import { Mixpanel } from '../../components/Mixpanel';
import Loader from "../../components/Loader";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// @material-ui/icons
import Column from '../../column';
import { DragDropContext } from 'react-beautiful-dnd';
// core components
import { api } from "../../lib/apisauce";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import stylescss from "../../assets/jss/material-kit-react/views/landingPage.js";
import Icon from "@material-ui/core/Icon/Icon";
import { UserConsumer } from "../../userContext";
import UserContext from "../../userContext";
import { Link } from 'react-router-dom';
import "./index.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const dashboardRoutes = [];
const styles = theme => (stylescss);

const Msg = ({ closeToast, lastWord, nextWord }) => (
    <div>
        <img src="https://i.imgur.com/CTrlKaI.png" style={{ width:"10vh", height:"auto", verticalAlign: "middle", float: "left" }} alt=""/>
        {nextWord != null && <div> Du hast {lastWord} geschrieben - nice, du könntest als nächstes das Wort "{nextWord}" beachten.</div>}
        {nextWord == null && <div> Du hast {lastWord} geschrieben - nice!</div>}
    </div>
);

class Category extends PureComponent {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.getNouns = this.getNouns.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    state = {
        items: ['Element 1', 'Element 2', 'Element 3', 'Element 4', 'Element 5', 'Element 6', 'Element 7', 'Element 8'],
        dashboard: '',
        research: '',
        question: '',
        searchTerm: '',
        prelist: [],
        result: {
            percent: 0,
            differ: []
        },
        context: '',
        editor: 1,
        saved: false,
        back: false,
        open: false,
        txt: '',
        resp: '',
        loadDbId: null,
        tasks: {
            'task-1': { id: 'task-1', content: 'Element 1' },
            'task-2': { id: 'task-2', content: 'Element 2' },
            'task-3': { id: 'task-3', content: 'Element 3' },
            'task-4': { id: 'task-4', content: 'Element 4' },
            'task-5': { id: 'task-5', content: 'Element 5' },
            'task-6': { id: 'task-6', content: 'Element 6' },
            'task-7': { id: 'task-7', content: 'Element 7' },
            'task-8': { id: 'task-8', content: 'Element 8' },
        },
        columns: {
            'column-1': {
                id: 'column-1',
                title: 'Unser Algorithmus verarbeitet, was die Welt in Bezug auf Dein Thema für wichtig hält.   ',
                taskIds: ['task-1', 'task-2', 'task-3', 'task-4', 'task-5', 'task-6', 'task-7', 'task-8'],
            },
            'column-3': {
                id: 'column-3',
                title: 'Wähle die Wörter aus, die Dich interessieren.',
                taskIds: [],
            },
            'column-2': {
                id: 'column-2',
                title: 'Versuche die folgenden Wörter in die Produktbeschreibung einzubauen:',
                taskIds: [],
            },
        },
        columnOrder: ['column-1', 'column-3', 'column-2'],
        loading: false,
        loadIde: this.props.match.params.ide,
        open2: false,
        userIdes: [],
        openAddElement: false,
        snackBarOpen: false,
        checker: [],
    };

    componentDidMount() {
        if (this.state.loadIde >= 1) { this.loadIdeDb(this.state.loadIde); }
        if (this.context.isLoggedIn) { this.loadUserSavedItems(this.context.username); }
        Mixpanel.identify(this.context.username);
        Mixpanel.track('User Visit at Jeff');

  var modal = document.getElementById("myModal");
  var modalkeys = document.getElementById("myModalKeys");
  const keySequence = [
      'n',
      's',
      'b',
  ];
  const keySequenceImprint = [
      'n',
      's',
      'i',
  ];
  const keySequencePizza = [
      'n',
      'p',
      'p',
  ];
  const keySequenceKeys = [
      'n',
      'k',
      'y',
  ];
  const keySequenceModal = [
      'n',
      'm',
      'm',
  ];
  const keySequenceAlien = [
      'n',
      'f',
      'f',
  ];
  const keySequenceRelax = [
      'n',
      'r',
      'r',
  ];
  const keySequenceBook = [
      'n',
      'b',
      'b',
  ];
  const keySequenceTab = [
      'n',
      't',
      't',
  ];
  const keySequenceHome = [
      'n',
      'd',
      'd',
  ];

  let userInput = new Array( keySequence.length );

  window.addEventListener( 'keydown', ( { key } ) => {
    userInput = [ ...userInput.slice( 1 ), key ];

    if ( keySequence.every( ( v, k ) => v === userInput[ k ] ) ) {
        window.open("/Blog", '_self');
    }
    if ( keySequenceImprint.every( ( v, k ) => v === userInput[ k ] ) ) {
        window.open("/Impressum", '_self');
    }
    if ( keySequenceHome.every( ( v, k ) => v === userInput[ k ] ) ) {
        window.open("/Home", '_self');
    }
    if ( keySequencePizza.every( ( v, k ) => v === userInput[ k ] ) ) {
        window.open("https://www.youtube.com/watch?v=O64DRTyNr3c");
    }
    if ( keySequenceAlien.every( ( v, k ) => v === userInput[ k ] ) ) {
        window.open("https://www.google.com/doodles/roswells-66th-anniversary");
    }
    if ( keySequenceRelax.every( ( v, k ) => v === userInput[ k ] ) ) {
        window.open("https://www.youtube.com/watch?v=XL4270xbaiw&t=");
    }
    if ( keySequenceBook.every( ( v, k ) => v === userInput[ k ] ) ) {
        window.open("https://www.amazon.com/Mathematicians-Apology-G-H-Hardy/dp/1466402695");
    }
    if ( keySequenceTab.every( ( v, k ) => v === userInput[ k ] ) ) {
        window.open();
    }
    if ( keySequenceModal.every( ( v, k ) => v === userInput[ k ] ) ) {
          modal.style.display = "block";
    }
    if ( keySequenceKeys.every( ( v, k ) => v === userInput[ k ] ) ) {
          modalkeys.style.display = "block";
    }
    } );


  document.onkeydown = function(e) {
          if(e.keyCode === 93) {
    modal.style.display = "block";
  }
  window.onclick = function(event) {
  if (event.target === modal) {
    modal.style.display = "none";
  }
  if (event.target === modalkeys) {
    modalkeys.style.display = "none";
  }
}
}
  }

    handleChange=(e)=>{
        let { value} = e.target;
        value = value.replace(/'/g, `'\\''`)
        this.setState({txt: value});
    }

    toggleLoad=(e)=> {
        this.setState({open2: !this.state.open2});
    }

    toggleElement=(e)=> {
        this.setState({openAddElement: !this.state.openAddElement});
    }

    handleChangeResp=(e)=>{
        let { value } = e.target;
        value = value.replace(/'/g, `'\\''`)

        this.setState({ question: value })

    }

    handleChangeMod=(e)=>{
        let { name, value} = e.target;
        this.setState({ [name]: value });
    }

    //onTxtInput = (searchTerm, toast) => {
    //    this.setState({ question: searchTerm })
        //this.setSearchTerm(searchTerm, true)
    //}

    onTxtInput = debounce((searchTerm, toast) => {
        this.setState({ question: searchTerm })
        this.checkPercent();
        this.isaHelp();
    }, 1000)


    addToTxt = (searchTerm, toast) => {
        let txt = this.state.question + " " + searchTerm + " "
        console.log(txt)
        this.setState({ question: txt })
        document.getElementById("question").value = txt;
    }

    setSearchTerm = debounce((searchTerm, toast) => {
        if(toast) this.isaHelp();
    }, 1000)

    isaHelp() {
        console.log(this.returnLastWord())
        if(this.handleCheck(this.returnLastWord())) this.notify();
    }

    checkPercent() {
        let countChecker = this.state.checker.length
        let rest = this.state.checker;
        let source = this.state.question.split(" ");

        let intersection = rest.filter(x => source.includes(x));
        let countIntersec = intersection.length

        let num = countIntersec / countChecker

        let perc = Math.round(num*100);

        if(perc === 0) perc = 1;
        if(perc > 100) perc = 100;
        var result = {...this.state.result}
        result.percent = perc;
        this.setState({result})

    }

    handleCheck(val) {
        return this.state.checker.some(item => val === item);
    }

    notify = () => {
        toast(<Msg lastWord={this.returnLastWord()} nextWord={this.returnLastWordPlusOne()}/>, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar',
            autoClose: 20000,
        });
    };

    returnLastWord() {
        var n = this.state.question.trim().split(" ");
        return n[n.length - 1];
    }

    returnLastWordPlusOne() {
        let index = this.state.checker.indexOf(this.returnLastWord());
        if (this.state.checker.length > index) return(this.state.checker[index+1])
        else return null;
    }


    onDragEnd = result => {

        const { destination, source, draggableId } = result;

        if(!destination) { return; }

        if(destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        const start = this.state.columns[source.droppableId];
        const finish = this.state.columns[destination.droppableId];

        if(start === finish) {
            const newTaskIds = Array.from(start.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                taskIds: newTaskIds,
            };

            const newState = {
                ...this.state,
                columns: {
                    ...this.state.columns,
                    [newColumn.id]: newColumn,
                },
            };

            this.setState(newState);

            if(destination.droppableId === 'column-3') {

                let myResearch = newState.columns["column-3"].taskIds.map((item, i) => {
                    return this.state.tasks[item].content
                });

                myResearch = myResearch.join('+');
                let myQuestion = myResearch.replace('+', ' ');

                this.setState({research: myResearch});

                const question = "Bitte sag uns was du hierüber gefunden hast: " + this.state.txt + " " + myQuestion + '?';

                this.setState({ question: question });
            }

            if(destination.droppableId === 'column-2') {

                let myDashboard = newState.columns["column-2"].taskIds.map((item, i) => {
                    return this.state.tasks[item].content
                });

                this.setState({dashboard: myDashboard});
            }

            return;
        }


        const startTaskIds = Array.from(start.taskIds);
        startTaskIds.splice(source.index, 1);
        const newStart = {
            ...start,
            taskIds: startTaskIds,
        };

        const finishTaskIds = Array.from(finish.taskIds);
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            taskIds: finishTaskIds,
        };

        const newState = {
            ...this.state,
            columns: {
                ...this.state.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
        };

        this.setState(newState);

        if(destination.droppableId === 'column-3') {

            let myResearch = newState.columns["column-3"].taskIds.map((item, i) => {
                return this.state.tasks[item].content
            });

            myResearch = myResearch.join('+');
            let myQuestion = myResearch.replace('+', ' ');
            this.setState({research: myResearch});

            const question = "Wir beziehen sich diese Keywords aufeinander? " + this.state.txt + " " + myQuestion + '?';
            this.setState({ question: question });

        }


    };

    onAddItem = (elem) => {
        let newItems = this.state.items;
        newItems.push(elem);

        let newTaskIds = newItems.map((item, index) => {
            const i = index + 1;
            const newState = {
                ...this.state,
                tasks: {
                    ...this.state.tasks,
                    ["task-" + i]: {id: "task-" + i, content: item},
                },
            };
            this.setState(newState);
            return "task-" + i;
        });

        let last = newTaskIds.pop()
        newTaskIds.splice(0, 0, last);

        this.setState({ items: newItems }, () => { this.updateColumn1(newTaskIds) });
    }

    updateColumn1(newTaskIds) {
        const finish = this.state.columns['column-1'];

        const newFinish = {
            ...finish,
            taskIds: newTaskIds,
        };

        const newState = {
            ...this.state,
            columns: {
                ...this.state.columns,
                [newFinish.id]: newFinish,
            },
        };

        this.setState(newState);
    }

    onDeleteItem = (column = 'column-1', id, index, xitem) => {
        let result = {
            destination: {
                droppableId: 'column-2',
                index: 1
            },
            source: {
                droppableId: column,
                index: index
            },
            draggableId: id
        }
        this.onDragEnd(result);

        let newItems = this.state.items.filter(function (item) {
            return item !== xitem;
        })

        this.setState({ items: newItems })
    }

    handleOpen() {
        this.setState({ open: true });
    };

    handleClose(){
        this.setState({ open: false });
    };

    switchIde(val) {
        this.setState({ editor: val });
    }




  render() {
      const { classes } = this.props;

      console.log(this.state)

                return (
                    <div>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                        <UserConsumer>
                            {({ username, password, isLoggedIn, loginFailure }) => (<Fragment>
                        {this.state.loading && <div className={classes.loader}>
                            <Loader />
                        </div>}
                        <Header
                            color="transparent"
                            backgroundColor="rgb(244, 244, 245)"
                            routes={dashboardRoutes}
                            brand="Ich bin Jeff und helfe Dir beim Schreiben."
                            changeColorOnScroll={{
                                height: 400,
                                color: "white"
                            }}
                        />

                               <div style={{ backgroundColor: "rgb(236, 236, 236)", paddingBottom: "4rem" }}>
                                   {!this.state.loadIde && (<GridContainer className={classes.pagecontainer} style={{ marginTop: "0rem" }}>
                                    <GridItem xs={12} sm={12} md={12}>

                                        <h1 className={classes.title} style={{ paddingBottom: "3rem" }}> Schreibe relevante Kategoriebeschreibungen.</h1>
                                        <p>Keine endlosen Stunden der Recherche mehr. Erstelle eine Kategoriebeschreibung zu einem Thema, das Dich interessiert. Natürlich SEO-optimiert.</p>
                                        <h3 style={{ paddingBottom: "3rem" }}>Schritt 1: Gib einen Kategorienamen ein: </h3>

                                        {this.state.prelist.length >= 1 &&
                                        (
                                            <a href={"/"}><Button color="primary"
                                                    style={{ marginTop: "1rem", color: "#fff" }}
                                            >Start new</Button></a>
                                        )
                                        }
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        {this.state.prelist.length <= 0 &&
                                        (
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <TextareaAutosize aria-label="minimum height" rows={1} rowsMax={1} placeholder="Du kannst ein Produkt Deiner Wahl suchen, zum Beispiel: japanischer Käsekuchen" onChange={this.handleChange}
                                                                      className={classes.textArea}
                                                                      defaultValue={this.state.txt ? this.state.txt : ''}
                                                                      style={{ marginLeft: "0" }}
                                                    />
                                                    <Button color="primary"
                                                            onClick={() => this.getPreList(this.state.txt)}
                                                            style={{ marginTop: "1rem", color: "#fff" }}
                                                    >Zeig mir interessante Produkte</Button>
                                                </GridItem>
                                            </GridContainer>
                                        )}
                                        {this.state.prelist.length >= 1 &&
                                        (
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", flexWrap: "wrap", padding: "1rem" }}>
                                                    Wir haben die folgenden Produkte gefunden, die passen könnten (bitte wähle eine aus):
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", flexWrap: "wrap" }}>

                                                    {this.state.prelist.map((item, ind) => {
                                                        return (
                                                            <Box className={classes.prelist} key={ind}
                                                                 onClick={() => this.getNouns(item.title)}
                                                            >{item.title}
                                                            </Box>)
                                                    })}
                                                </GridItem>
                                            </GridContainer>
                                        )
                                        }

                                    </GridItem>
                                </GridContainer>)}
                               </div>

                                <div style={{ backgroundColor: "white", paddingBottom: "4rem" }} ref={this.myRef}>
                                <GridContainer className={classes.pagecontainer} style={{ paddingTop: "3rem", backgroundColor: "white" }}>
                                    <Box
                                            display="flex"
                                            p={0}
                                            m={0}
                                            bgcolor="transparent"
                                            width={"100%"}
                                            flexDirection={"column"}

                                        >
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h3>Schritt 2: Schreibe einen Text!</h3>
                                                <p> Schreibe über alles, was Du willst. Beginne mit der Auswahl der Keywords unten, die Dich interessieren, und verfasse einen Text. Verwende dafür unseren intelligenten Editor. </p>
                                            </GridItem>
                                            <Box
                                                display="flex"
                                                p={0}
                                                m={0}
                                                bgcolor="transparent"
                                                width={"100%"}
                                                flexDirection={"row"}
                                            >


                                                {this.state.columnOrder.map((columnId, ind) => {
                                                    if(columnId !== 'column-2' && columnId !== 'column-3') {

                                                        const column = this.state.columns[columnId];
                                                        const tasks = column.taskIds.map(taskId => this.state.tasks[taskId]);
                                                        return (<Box width={"100%"} key={ind}><Column key={column.id} column={column} tasks={tasks}
                                                                                                     dashboard={this.state.dashboard}
                                                                                                     research={this.state.research} topic={this.state.txt}
                                                                                                     saveDashboard={this.saveDashboard}
                                                                                                     onDeleteItem={this.onDeleteItem}
                                                                                                    openAddelement={this.state.openAddElement}
                                                                                                     toggleElement={this.toggleElement}
                                                                                                     onAddItem={this.onAddItem}
                                                                                                     saved={this.state.saved}
                                                                                                      addToTxt={this.addToTxt}/></Box>)
                                                    } else {
                                                        return null;
                                                    }

                                                    })
                                                }


                                            </Box>
                                            <Box
                                                display="flex"
                                                p={0}
                                                m={0}
                                                bgcolor="transparent"
                                                width={"100%"}
                                                flexDirection={"row"}
                                            >
                                                <Box className={classes.containerIde}>


                                                    <Grid container spacing={2}>

                                                        <GridItem xs={10}>
                                                            <GridItem xs={12}>
                                                                <Box display="flex" flexDirection="row" justifyContent="right" alignItems={"center"} className={classes.iconbox} style={{ alignItems: "center" }}>
                                                                    <Box className={classes.icon1}>
                                                                        <Icon style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => this.saveIde(username)}>save_alt</Icon>
                                                                    </Box>
                                                                    <Box className={classes.icon1}>
                                                                        <Icon style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => this.toggleLoad()}>folder_open</Icon>
                                                                    </Box>
                                                                    <Box>
                                                                        Du arbeitest im Moment an: {this.state.context} {this.state.research}
                                                                    </Box>
                                                                    {this.state.saved && (
                                                                        <Box>
                                                                        Deine <Link to={"/Home"}>Produktbeschreibung</Link> wurde gespeichert.
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            </GridItem>
                                                            {this.state.open2 && (<Grid container spacing={4} style={{ padding: "0.5rem" }}><GridItem xs={12}>
                                                                <Box display="flex" flexDirection="row" justifyContent="right" alignItems={"center"} className={classes.iconbox} style={{ alignItems: "center" }}>
                                                                    {username !== 'user' && (
                                                                        <Box>
                                                                            {this.state.userIdes.map((ide, ind) => {
                                                                                return (<Box style={{ width: "100%" }}><Link to={"/load/"+ide.id} key={ind}>{ide.context} {ide.research} {ide.crdate}</Link></Box>)
                                                                            })}
                                                                        </Box>
                                                                    )}
                                                                    {username === 'user' && (
                                                                        <Box>
                                                                            Bitte logge Dich ein, um Deine gespeicherten Produktbeschreibungen zu sehen. <Link to="/Signin">sign in</Link>
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            </GridItem></Grid>)}
                                                    <Box>

                                                            <TextareaAutosize id={"question"} aria-label="minimum height" rows={10} rowsMax={10} placeholder={this.state.question} onChange={e => {this.onTxtInput(e.target.value, true)}}
                                                                              defaultValue={this.state.question}
                                                                              className={classes.textArea1} style={{ marginLeft: "0", background: "#fff", fontFamily: '"Ubuntu Mono",monospace', paddingTop: "4px !important", width: "99%", color: "#333" }}
                                                            />
                                                        {/*<Button color="primary"
                                                                onClick={() => this.getCheck()}
                                                                style={{ marginTop: "1rem" }}
                                                        >Überprüfe, wie gut Du das Thema abgedeckt hast.</Button>*/}
                                                    </Box>
                                                        </GridItem>


                                                        <GridItem xs={12}>
                                                            <Box>
                                                            <Grid container style={{padding: "1rem"}}>
                                                                <p id="simple-modal-description">
                                                                    Dein Text deckt im Moment {this.state.result.percent} % der wesentlichen Begriffe ab.
                                                                </p>

                                                                <GridItem xs={12}>
                                                                    <div style={{ margin: "0 auto", textAlign: "center", alignSelf: "center"}}>
                                                                        <CircularProgressbar
                                                                            value={this.state.result.percent}
                                                                            text={`${this.state.result.percent}%`}
                                                                            styles={buildStyles({
                                                                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                                strokeLinecap: 'round',
                                                                                // Colors
                                                                                pathColor: '#ff9900',
                                                                                textColor: '#ff9900',
                                                                            })}

                                                                        />
                                                                    </div>
                                                                </GridItem>
                                                                <GridItem xs={9}>


                                                                </GridItem>

                                                                <GridItem xs={12}>

                                                        <div>


                                                        </div>
                                                                </GridItem>
                                                            </Grid>
                                                            </Box>
                                                        </GridItem>

                                                    </Grid>


                                                </Box>
                                            </Box>
                                        </Box>


                                </GridContainer>
                                </div>

                          <div id="myModal" className="modal">
                            <div style={{ top: "50%", left: "50%", transform: `translate(-50%, -50%)`, }} className={classes.modalpaper}>

                            <TextareaAutosize id={"question"} aria-label="minimum height" rows={10} rowsMax={10} placeholder={"You can use this input field to add elements to your project. Type: get.jeff.products limit top 3"} onChange={e => {this.setSearchTerm(e.target.value)}}
                                              className={classes.textArea1}  defaultValue={"You can use this input field to add elements to your project. Type: get.jeff.products limit top 3"}
                                              style={{ marginLeft: "0", background: "#fff", fontFamily: '"Ubuntu Mono",monospace', paddingTop: "4px !important", width: "99%", color: "#333" }}
                            />

                            </div>
                        </div>
                        <div id="myModalKeys" className="modal">
                          <div style={{ top: "50%", left: "50%", transform: `translate(-50%, -50%)`, }} className={classes.modalpaper}>

                        <Box>
                        <div>

                        <h1>Jeff's ShortCatz 😸 </h1>
                        </div>
                        <p> Du kannst Jeff mit ShortCatz von deiner Tastatur aus benutzen. Einfach und schnell. </p>
                        <Grid item xs={12} sm container>
                          <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                              <Typography variant="body2" gutterBottom>
                              <b>Um Deine ShortCatz zu laden:</b> Drücke die Taste <b>N</b> dann <b>M</b> und dann die Taste <b>M</b><br/>
                              <b>Um Dashboard zu laden:</b> Drücke die Taste <b>N</b> dann <b>D</b> und dann die Taste <b>D</b><br/>
                              <b>Um unseren Blog zu laden:</b> Drücke die Taste <b>N</b> dann <b>S</b> und dann die Taste <b>B</b><br/>
                              <b> Um die Buchempfehlung des Monats zu erhalten:</b> Drücke die Taste <b>N</b> dann <b>B</b> und dann die Taste <b>B</b><br/>
                              <b>Ausserirdischer sein:</b> Drücke die Taste <b>N</b> dann <b>F</b> und dann die Taste <b>F</b><br/>
                              <b>Um eine neue Registerkarte zu laden:</b> Drücke die Taste <b>N</b> dann <b>T</b> und dann die Taste <b>T</b><br/>
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" style={{ cursor: 'pointer' }}>
                                <img className={classes.img} alt="complex" src="https://i.imgur.com/CTrlKaI.png" width="20vh" height="auto" /> You worked on this! Well done.
                              </Typography>
                            </Grid>
                        </Grid>
                        </Grid>
                        </Box>

                          </div>
                      </div>

                        <Footer/>
                                </Fragment>)}
                        </UserConsumer>
                        </DragDropContext>
                        <button onClick={this.notify}>Gibt es eine Empfehlung für mich?</button>
                        <ToastContainer />
                    </div>

                );
            }


    /**
     *
     * @method getNouns
     * @memberof LandingPage
     * WIP
     */
    getNouns = async item => {

        this.setState({ loading: true });

        this.setState({ context: item });

        const response = await api.post(
            "https://apitp.tensorparc.com/jeff/getWP.php",
            { item }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            this.setState({ items: data.result.items, back: true });

            this.setState({ checker: data.result.checker });

            let newState = [];

            this.state.items.map((item, index) => {
                const i = index + 1;
                newState = {
                    ...this.state,
                    tasks: {
                        ...newState.tasks,
                        ["task-" + i]: {id: "task-" + i, content: item},
                    },
                };
                return newState;
            });

            this.setState(newState);

            const newItems = [];
            this.state.items.map((item, index) => {
                const i = index + 1
                newItems[index] = 'task-'+i
                return true;
            });

            const newColumns = {
                    'column-1': {
                        id: 'column-1',
                        title: 'Unser Algorithmus verarbeitet, was die Welt in Bezug auf Dein Thema für wichtig hält.  ',
                        taskIds: newItems,
                    },
                    'column-3': {
                        id: 'column-3',
                        title: 'Wähle die Wörter aus, die Dich interessieren.',
                        taskIds: [],
                    },
                    'column-2': {
                        id: 'column-2',
                        title: 'Versuche die folgenden Wörter in die Produktbeschreibung einzubauen:',
                        taskIds: [],
                    },
            };

            this.setState({ columns: newColumns});

            this.setState({ loading: false });

            this.myRef.current.scrollIntoView({block: 'start', behavior: 'smooth'})

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

    /**
     *
     * @method loadUserSavedItems
     * @memberof LandingPage
     * WIP
     */
    loadUserSavedItems = async (user) => {
        const response = await api.post(
            "https://apitp.tensorparc.com/getItems.php",
            { user }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)
            this.setState({ userIdes: data.result});

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
        }
    };

    /**
     *
     * @method saveDashboard
     * @memberof LandingPage
     * WIP
     */
    saveIde = async username => {
        const dashboard = JSON.stringify(this.state.result.differ);
        const research = JSON.stringify(this.state.research);
        const items = JSON.stringify(this.state.items);
        const check = JSON.stringify(this.state.checker);
        const context = JSON.stringify(this.state.context);
        const score = this.state.result.percent;
        const ide = this.state.question;
        const response = await api.post(
            "https://apitp.tensorparc.com/jeff/putDashboard.php",
            { dashboard, ide, research, items, check, context, username, score }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ saved: true});
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
        }
    };

    /**
     *
     * @method loadIde
     * @memberof LandingPage
     * WIP
     */
    loadIdeDb = async loadIde => {
        this.setState({ loading: true });

        const response = await api.post(
            "https://apitp.tensorparc.com/jeff/getDashboard.php",
            { loadIde }
        );
        const { ok, data } = response || {};
        if (ok) {

            this.setState({ items: data.result[0].items, back: true });

            this.setState({ checker: data.result[0].checkit })

            data.result[0].items.map((item, index) => {
                const i = index + 1;
                const newState = {
                    ...this.state,
                    tasks: {
                        ...this.state.tasks,
                        ["task-" + i]: {id: "task-" + i, content: item},
                    },
                };
                this.setState(newState);
                return true;
            });

            const newItems = [];
            data.result[0].items.map((item, index) => {
                const i = index + 1;
                if(data.result[0].research.some(function checkResearch(now) { return now === item; })) {  }
                else { newItems.push('task-'+i)     }
                return true;
            });

            const newItems1 = [];
            let x = 0;
            data.result[0].items.map((item, index) => {
                const i = index + 1;
                if(data.result[0].research.some(function checkResearch(now) { return now === item; })) { newItems1.push('task-'+i); x = x + 1; }
                return true;
            });

            this.setState({

                columns: {
                    'column-1': {
                        id: 'column-1',
                        title: 'Unser Algorithmus verarbeitet, was die Welt in Bezug auf Dein Thema für wichtig hält.   ',
                        taskIds: newItems,
                    },
                    'column-3': {
                        id: 'column-3',
                        title: 'Wähle die Wörter aus, die Dich interessieren.',
                        taskIds: newItems1,
                    },
                    'column-2': {
                        id: 'column-2',
                        title: 'Versuche die folgenden Wörter in die Produktbeschreibung einzubauen:',
                        taskIds: [],
                    },
                },


            });



            const tmpresult = {
                percent: data.result[0].score,
                differ: data.result[0].dashboard
            }

            this.setState({ result: tmpresult });
            this.setState({ question: data.result[0].ide });
            this.setSearchTerm(data.result[0].ide, false);
            this.setState({ context: data.result[0].context });
            this.setState({ research: data.result[0].researchstr });
            this.setState({ loading: false });
            this.setState({ open: true});


            /*
                        columns: {
                                'column-1': {
                                    id: 'column-1',
                                    title: 'Our algorithm processes what the world considers important regarding your topic. Drag those words that interest you into the research field on the right. ',
                                    taskIds: ['task-1', 'task-2', 'task-3', 'task-4', 'task-5', 'task-6', 'task-7', 'task-8'],
                                },
                                'column-3': {
                                    id: 'column-3',
                                    title: 'Select those words that interest you. Learn and research them well by building a project with our tools on Step 3',
                                    taskIds: [],
                                },
                                'column-2': {
                                    id: 'column-2',
                                    title: 'Try to include the following keywords to your project:',
                                    taskIds: [],
                                },
                            },
                            columnOrder: ['column-1', 'column-3', 'column-2'], loading: false  })
            */


        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("loadDashboard() Error", { data });
            }
        }
    };

    /**
     *
     * @method getCheck
     * @memberof LandingPage
     * WIP
     */
    getCheck = async () => {

        this.setState({ loading: true });

        const question = this.state.question;
        const topic = this.state.txt;
        const research = this.state.research;
        const fallbackelements = this.state.items;

        const response = await api.post(
            "https://apitp.tensorparc.com/jeff/getAnswer.php",
            { question, topic, research, fallbackelements }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ result: data });
            this.setState({ loading: false });
            this.setState({ open: true });

            let differ = Object.values(data.differ);
            differ.map((item, index) => {
                const i = index + 1;
                const newState = {
                    ...this.state,
                    tasks: {
                        ...this.state.tasks,
                        ["task-" + i]: {id: "task-" + i, content: item},
                    },
                };
                this.setState(newState);
                return true;
            });

            const newItems = [];
            differ.map((item, index) => {
                const i = index + 1
                newItems[index] = 'task-'+i
                return true;
            });


            const newState1 = {
                ...this.state,
                columns: {
                    ...this.state.columns,
                    'column-2': {
                        id: 'column-2',
                        title: 'Try researching the following keywords:',
                        taskIds: newItems,
                    }
                }
            };
            this.setState(newState1);


        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

    /**
     *
     * @method getPreList
     * @memberof LandingPage
     * WIP
     */
    getPreList = async () => {
        this.setState({ loading: true });
        const topic = this.state.txt;
        const response = await api.post(
            "https://apitp.tensorparc.com/jeff/getWPpages.php",
            { topic }
        );
        const { ok, data } = response || {};
        if (ok) {
            this.setState({ prelist: data });
            this.setState({ loading: false });
        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("fetchNouns() Error", { data });
            }
        }
    };

}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export default withStyles(styles, { withTheme: true })(Category);
