import React, { Fragment } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Droppable } from 'react-beautiful-dnd';
import Task from './task'
import SimpleIcons from 'simple-icons-react-component';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Icon from "@material-ui/core/Icon/Icon";
import TextField from '@material-ui/core/TextField';

const mystyles = {
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        padding: "8px",
        fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
        color: "rgb(63, 64, 63)",
        fontSize: "16px",
        marginBlockEnd: "0"
    },
    container: {
        margin: "8px",
        border: "1px solid lightgrey",
        borderRadius: "2px",
        height: "100%",
        minHeight: "100px"
    },
    tasklist: {
        padding: "8px",
        minHeight: "100px",
        height: "100%",
    },
    iconbox: {
        width: "100%"
    },
    icon1: {
        width: "20px",
        height: "20px",
        margin: "10px",
        padding: "5px",
        border: "1px solid lightgrey",
    },
    icon2: {
        width: "40px",
        height: "20px",
        margin: "10px",
        padding: "5px",
        border: "none",
    },
    palette: {
        primary: "#ff9900",
    },
    textField: {
        "&$focused": {
            color: "tomato",
            fontWeight: "bold"
        }
    },
};

const styles = theme => (mystyles);

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#ff9900',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#ff9900',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'grey',
            },
            '&:hover fieldset': {
                borderColor: 'grey',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ff9900',
            },
        },
    },
})(TextField);


class Column extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    state = {
        elem: '',
    }

    handleChange(event) {
        this.setState({elem: event.target.value});
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.container}  style={{ backgroundColor: "rgb(236, 236, 236)"}}>
                <h3 className={classes.title}>{this.props.column.title}</h3>
                {this.props.column.id === 'column-5' &&
                    <Box display="flex" flexDirection="row" justifyContent="center" className={classes.iconbox}>
                        <Box className={classes.icon1}>
                            {this.props.tasks.length >= 1 ? <Link
                                href={"http://www.google.com/search?q=example%20of%20project%20" + this.props.topic + "+" + this.props.research}
                                component="a"
                                target="_blank"
                            >
                                <SimpleIcons name="Google"/>
                            </Link> : <SimpleIcons name="Google"/>}
                        </Box>
                        <Box className={classes.icon1}>
                            {this.props.tasks.length >= 1 ? <Link
                                href={"https://www.reddit.com/search/?q=" + this.props.topic + "+" + this.props.research + "HowTo"}
                                component="a"
                                target="_blank"
                            >
                                <SimpleIcons name="Reddit"/>
                            </Link> : <SimpleIcons name="Reddit"/>}
                        </Box>
                        <Box className={classes.icon1}>
                            {this.props.tasks.length >= 1 ? <Link
                                href={"https://hn.algolia.com/?q=%20project%20" + this.props.topic + "+" + this.props.research}
                                component="a"
                                target="_blank"
                            >
                                <SimpleIcons name="Y Combinator"/>
                            </Link> : <SimpleIcons name="Y Combinator"/>}
                        </Box>
                        <Box className={classes.icon1}>
                            {this.props.tasks.length >= 1 ? <Link
                                href={"https://stackoverflow.com/search?q=" + this.props.topic + "+" + this.props.research}
                                component="a"
                                target="_blank"
                            >
                                <SimpleIcons name="Stack Overflow"/>
                            </Link> : <SimpleIcons name="Stack Overflow"/>}
                        </Box>
                        <Box className={classes.icon1}>
                            {this.props.tasks.length >= 1 ? <Link
                                href={"https://github.com/search?q=" + this.props.topic + "+" + this.props.research}
                                component="a"
                                target="_blank"
                            >
                                <SimpleIcons name="GitHub"/>
                            </Link> : <SimpleIcons name="GitHub"/>}
                        </Box>
                    </Box>
                }
                {this.props.column.id === 'column-9' || this.props.column.id === 'column-5' &&
                <Box display="flex" flexDirection="row" justifyContent="center" className={classes.iconbox} style={{ alignItems: "center" }}>
                    <Box className={classes.icon1}>
                        <Icon style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => this.props.toggleElement()}>add</Icon>
                    </Box>
                    {this.props.openAddelement && <Fragment>
                        <CssTextField
                            id="elem"
                            name="elem"
                            label="Element"
                            className={classes.textField}
                            value={this.state.elem}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                        />
                        <Box className={classes.icon1}>
                            <Icon style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => this.props.onAddItem(this.state.elem)}>save</Icon>
                        </Box>
                        </Fragment>}
                </Box>
                }
                <Droppable droppableId={this.props.column.id}>
                    {provided => (
                        <div className={classes.tasklist}
                            ref={provided.innerRef}
                             {...provided.droppableProps}
                            style={{
                                maxHeight: this.props.column.id === 'column-5' ? "11.5rem" : "15rem",
                                overflowX: "hidden",
                                overflowY: "scroll" }}
                        >
                            {this.props.tasks.map((task, index) => (
                                <Task key={task.id} task={task} index={index} onDeleteItem={this.props.onDeleteItem} colId={this.props.column.id} addToTxt={this.props.addToTxt}/>
                            ))}
                            {provided.placeholder}
                            {this.props.tasks.length <= 0 && this.props.column.id === 'column-2' && <div style={{ color: "rgb(117, 117, 117)", fontSize: "14px"  }}>drag from here to research</div>}
                            {this.props.tasks.length <= 0 && this.props.column.id === 'column-5' && <div style={{ color: "rgb(117, 117, 117)", fontSize: "14px"  }}>drop elements here to use our Algorithm</div>}
                        </div>
                    )}
                </Droppable>

            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Column);
