import React, { PureComponent, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import 'react-circular-progressbar/dist/styles.css';

import stylescss from "../../assets/jss/material-kit-react/views/landingPage.js";
const dashboardRoutes = [];
const styles = theme => (stylescss);

class ConfirmDeletion extends PureComponent {



    render() {
        const { classes } = this.props;
        return (
            <div>

                    <Fragment>

                        <Header
                            color="transparent"
                            backgroundColor="rgb(244, 244, 245)"
                            routes={dashboardRoutes}
                            brand="Jeff ist der beste Fuchs."
                            fixed
                            changeColorOnScroll={{
                                height: 400,
                                color: "white"
                            }}
                        />


                        <div style={{ backgroundColor: "rgb(236, 236, 236)", paddingBottom: "4rem" }}>
                            <GridContainer className={classes.pagecontainer} style={{ paddingTop: "10rem" }}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <h3 style={{ paddingBottom: "3rem" }}>Es tut uns leid, Dich gehen zu sehen. Dein Konto wurde gelöscht.</h3>
                                    <a href="https://www.youtube.com/watch?v=MKWWhf8RAV8">Hier ist ein Video als letztes Bonbon. Nur um sich zu verabschieden.</a>
                                </GridItem>


                            </GridContainer>
                        </div>

                        <Footer/>
                    </Fragment>

            </div>
        );
    }


}



export default withStyles(styles, { withTheme: true })(ConfirmDeletion);
