import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from "react-router-dom";

export const mainListItems = (
    <div>
        <ListItem button component={Link} to="/Home">
            <ListItemIcon>
                <img src="https://i.imgur.com/QCPOKE3.png" style={{ padding:"0", width:"5vh", height:"auto", verticalAlign: "sub" }} alt=""/>
            </ListItemIcon>
                <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/Home">
            <ListItemIcon>
                <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="MyProjects" />
        </ListItem>
        <ListItem button component={Link} to="/">
            <ListItemIcon>
                <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Skill Profile" />
        </ListItem>
    </div>
);

export const secondaryListItems = (
    <div>
        <ListSubheader inset>Help Area</ListSubheader>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="How does this work?" />
        </ListItem>
    </div>
);
