import React from 'react';
import { UserConsumer } from './userContext';

export default function UserLoginFail() {
    return (
        <UserConsumer>
            {props => {
                return props.loginFailure ? <div style={{ color: "red" }}>Das hat leider nicht geklappt, bitte überprüfe Deinen Nutzernamen und Dein Passwort</div> : <div></div>;
            }}
        </UserConsumer>
    );
}
