// Root entry point
import React from "react";
import Helmet from "react-helmet";
import { Switch, Route } from "react-router-dom";

import Home from "../../containers/Home"
import ScrollTop from "../../components/ScrollTop";

import { CookieBanner } from '@palmabit/react-cookie-law';


import { publicRoutes } from "../../routes";

const Root = () => (

    <div>
        <Helmet>
            <title>TensorParc - The learning frontpage of the Internet</title>
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        </Helmet>
        <ScrollTop>

            <Switch>
                {publicRoutes.map(route => (
                    <Route key={route.path} {...route} />
                ))}
                {/* default route when path does not match anything */}
                <Route component={Home} />
            </Switch>
        </ScrollTop>
        <div>
            <CookieBanner
                message="We use cookies so that we can offer you the best possible website experience. Yummy Yummy. This includes cookies which are necessary for the operation of the website and to manage our corporate commercial objectives, as well as other cookies which are used solely for anonymous statistical purposes, for more comfortable website settings, or for the display of personalised content. You are free to decide which categories you would like to permit. Please note that depending on the settings you choose, the full functionality of the website may no longer be available. Further information can be found in our privacy policy."
                policyLink="/Privacy"
                showPreferencesOption={false}
                onAccept = {() => {}}
                onAcceptStatistics = {() => {}}
                onAcceptMarketing = {() => {}}
                styles={{
                    dialog: {
                        position: 'fixed',
                        bottom: '0px',
                        left: '0px',
                        right: '0px',
                        zIndex: '100000',
                        backgroundColor: 'rgb(248, 247, 247)',
                        padding: '10px',
                        borderTop: '2px solid #ff9900',
                    },
                    button: {
                        backgroundColor: '#ff9900',
                        color: 'rgb(255, 255, 255)',
                        border: 'none',
                        cursor: 'pointer',
                        margin: '.3125rem 1px',
                        padding: '12px 30px',
                        position: 'relative',
                        fontSize: '12px',
                        minWidth: 'auto',
                        textTransform: 'uppercase',
                    },
                        policy: {
                        fontSize: '10pt',
                        color: '#ff9900',
                        textDecoration: 'underline',
                    },
                        checkbox: {
                        position: 'absolute',
                        left: '0px',
                        width: '14px',
                        height: '14px',
                        zIndex: '2',
                        cursor: 'pointer',
                    }
                }}
            />
        </div>
    </div>
);

export default Root;
