import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import './index.css';
import * as serviceWorker from './serviceWorker';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider, MixpanelConsumer } from 'react-mixpanel';

import Root from "./containers/Root";

import { UserProvider } from './userContext';

// Create a browser history
const history = createBrowserHistory();

ReactDOM.render(
    <Router history={history}>
    <MixpanelProvider mixpanel={mixpanel}>
    <UserProvider>
        <Root />
        </UserProvider>
          </MixpanelProvider>
    </Router>
    ,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
