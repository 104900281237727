/** @jsx jsx */
import { jsx } from "@emotion/core";
import AceEditor, { split as SplitEditor } from "react-ace";
import { getEditorBaseConfig } from "./config";
import './style.css';
// import { Java } from './Java';
// import { JavaScript } from './JavaScript';



/**
 *
 *
 * @export
 * @returns {React.ReactDom}
 */
export function IDE(props) {
  const IDEConfig = getEditorBaseConfig(props);

  console.log({ props, IDEConfig });

  if (props.splits) {
    return (
      <div className="ideContainerCss split">
        <SplitEditor {...IDEConfig} />
      </div>
    );
  }

  return (
    <div className="ideContainerCss">
      <AceEditor {...IDEConfig} />
    </div>
  );
}
