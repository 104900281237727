import React from 'react';
import { UserConsumer } from './userContext';

export default function UserMessage() {
    return (
        <UserConsumer>
            {props => {
                return (props.username && props.isLoggedIn) ? <h4 style={{marginLeft:"2em", marginRight: "1em"}}>Hi {props.username}!</h4> : <div></div>;
            }}
        </UserConsumer>
    );
}
