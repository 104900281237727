import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Draggable } from 'react-beautiful-dnd';
import Container from '@material-ui/core/Container';
import SimpleIcons from 'simple-icons-react-component';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Icon from "@material-ui/core/Icon/Icon";

const mystyles = {
    container: {
        marginBottom: "8px",
        fontFamily: '"PT Mono", monospace',
        borderRadius: "3px",
        padding: "8px",
        backgroundColor: "white",
        color: "rgb(63, 64, 63)",
        borderBottom: "1px solid",
        borderBottomColor: "rgb(217, 217, 217)",
        fontSize: "16px",
    }
};

const styles = theme => (mystyles);

class Task extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Draggable draggableId={this.props.task.id} index={this.props.index}>
                {(provided, snapshot) => (
                    <Container
                        className={classes.container}
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                            ref={provided.innerRef}
                    >
                    <Box display="flex" flexDirection="row">
                        <Box style={{ marginRight: "3rem" }}>{this.props.task.content}</Box>
                        <Box style={{ width: "12px", height: "12px", marginLeft: "auto", marginRight: "1rem" }}><Link
                            href={"https://www.google.com/search?tbm=shop&q=" + this.props.task.content}
                            component="a"
                            target="_blank"
                        >
                            <SimpleIcons name="Google"/></Link></Box>
                        <Box style={{ width: "12px", height: "12px", marginRight: "1rem" }}><Link
                            href={"https://www.amazon.de/s?k=" + this.props.task.content}
                            component="a"
                            target="_blank"
                        >
                            <SimpleIcons name="Amazon"/></Link></Box>
                        <Box style={{ marginRight: "1rem" }}>
                            <Icon style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => this.props.addToTxt(this.props.task.content, false)}>system_update_alt</Icon>
                        </Box>
                        <Box>
                            <Icon style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => this.props.onDeleteItem('column-1', this.props.task.id, this.props.index, this.props.task.content)}>close</Icon>
                        </Box>


                    </Box>
                    </Container>
                )}
            </Draggable>
        )
    }

}

export default withStyles(styles, { withTheme: true })(Task);
