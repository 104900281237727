import { container, title } from "../../../../assets/jss/material-kit-react.js";

const landingPageStyle = {

    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#000',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#000'
        },
    },

    inputLabel: {
        "&.focused": {
            color: "orange"
        },
    },

  container: {
    zIndex: "12",
    color: "#000",
      backgroundColor: "rgb(236, 236, 236)",
    ...container
  },
    pagecontainer: {
        zIndex: "12",
        color: "#000",
        backgroundColor: "rgb(236, 236, 236)",
        marginTop: "4rem",
        ...container
    },
    maincontainer: {
        backgroundColor: "rgb(236, 236, 236)",
    },

    root: {
        color: "#000 !important",
        '&$focused': {
            color: "#000",
        },
    },
    /* Pseudo-class applied to the root element if `focused={true}`. */
    focused: {},
    MuiInputLabel: {
        outlined: {
            '&$focuesd': {
                color: '#000',
            },
        },
    },

  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "rgb(63, 64, 63)",
    textDecoration: "none",
    fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
    fontSize: "2vw",
    "@media (min-width: 1045px)": {
        fontSize: "1.5rem"
    }
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
    section: {
        padding: "70px 0"
    },
    description: {
        color: "#999",
        textAlign: "center"
    },
    textCenter: {
        textAlign: "center"
    },
    textArea: {
        border: "none",
        overflow: "auto",
        outline: "none",
        marginRight: "15px",
        marginLeft: "15px",
        opacity: "1",
        fontSize: "14px",
        fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
        fontWeight: "400",
        lineHeight: "1.42857",
        width: "100%",
        background: "none",
        borderColor: "transparent",
        borderBottom: "2px solid",
        borderBottomColor: "lightgrey",
        color: "rgb(63, 64, 63)",

    },
    textArea1: {
        overflow: "auto",
        outline: "none",
        marginRight: "15px",
        marginLeft: "15px",
        opacity: "1",
        fontSize: "15px",
        fontFamily: '\'Ubuntu Mono\',monospace',
        fontWeight: "400",
        lineHeight: "1.42857",
        width: "100%",
        color: "rgb(151, 137, 152)",
        background: "#fff",
        borderRadius: "3pt",
        border: "1px solid rgb(232, 229, 232)",
        paddingLeft: "8px",
        paddingTop: "4px"
    },
    textField: {
        width: "100%",
        color: "orange",
        "&.Mui-focused": {
            color: "orange"
        }
    },
    chip: {
        marginRight:"1rem",
        marginBottom: "1rem"
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: "#fff",
        border: '2px solid lightgrey',
        boxShadow: "lightgrey",
        padding: "1rem",
        outline: 'none'
    },
    modalpaper: {
        position: 'absolute',
        width: "50%",
        backgroundColor: "#fff",
        border: 'none',
        boxShadow: "lightgrey",
        padding: "1rem",
        outline: 'none',
        borderRadius: "3pt",
    },
    loader: {
        alignItems:"center",
        justifyContent:"center",
        mt:"50%"
    },
    iconbox: {
        width: "100%",
        height: "100%",
        alignItems: "flex-end",
    },
    icon1: {
        width: "20px",
        height: "20px",
        margin: "10px",
        padding: "5px",
        border: "1px solid lightgrey",
    },
    icon2: {
        width: "40px",
        height: "20px",
        margin: "10px",
        padding: "5px",
        border: "none",
    },
    icon3: {
        width: "95px",
        height: "20px",
        margin: "10px",
        padding: "5px",
        border: "1px solid lightgrey",
    },
    icon4: {
        width: "100px",
        height: "20px",
        margin: "10px",
        padding: "5px",
        border: "1px solid lightgrey",
    },
    icon5: {
        width: "20px",
        height: "20px",
        margin: "0px",
        padding: "0px",
        border: "none",
    },
    textarea: {
        fontFamily: '\'Ubuntu Mono\',monospace',
    },
    prelist: {
      padding: "1rem",
      borderColor: "rgb(217, 217, 217)",
        backgroundColor: "#ff9900",
        margin: "0.5rem",
        color: "white",
        cursor: "pointer"
    },
    containerIde: {
        margin: "8px",
        border: "1px solid lightgrey",
        borderRadius: "2px",
        height: "100%",
        minHeight: "100px",
        backgroundColor: "rgb(236, 236, 236)",
        padding: "0.5rem",
        marginTop: "1.5rem",
        width: "100%"
    },
    focus:{
        color:'orange'
    },
    formLabel: {
        color: "#000",
        "&.Mui-focused": {
            color: "#000"
        }
    },
};

export default landingPageStyle;
