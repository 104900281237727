import { create } from "apisauce";
import bowser from "bowser";

let origin = "http://localhost:3000/";

if (typeof window !== "undefined") {
    origin = window.location.origin;
}

export const api = create({
    baseURL: `${origin}`,
    headers: {
        // "Content-Type": "application/x-www-form-urlencoded"
        // "Access-Control-Allow-Credentials": true
        "Content-Type": "application/json"
    }
});
api.addRequestTransform(request => {
    if (bowser.msie && request.method === "get") {
        // There is a known issue in IE :
        // Axios only called once inside self-invoking function (Internet Explorer)
        // https://stackoverflow.com/questions/45830531/axios-only-called-once-inside-self-invoking-function-internet-explorer/45835054#45835054
        // Here is a workaround, as we need to found a more generic way to handle this issue for each get API call
        request.headers.Pragma = "no-cache";
        request.headers["Cache-Control"] = "no-cache, no-store, must-revalidate";
        request.headers.Expires = "0";
    }
});
