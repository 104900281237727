import React from 'react';
import Button from "@material-ui/core/Button/Button";
import { UserConsumer } from './userContext';

export default function UserMessage() {
    return (
        <UserConsumer style={{marginLeft:"2em"}}>
            {props => {
                return (props.username && props.isLoggedIn) ? <Button type="button"
                                                                      variant="contained"
                                                                      onClick={event => { props.logout(); }}>
                    Log out
                </Button> : <div></div>;
            }}
        </UserConsumer>
    );
}
